
import { Component, Vue } from 'vue-property-decorator'
import Order from './Order.vue'
import { PlanDetail } from '@/types/plan.d'

@Component({
  components: { Order }
})
export default class Detail extends Vue {
  private loading = false
  private infoData: PlanDetail = {
    projectId: '',
    planMonth: '',
    introduce: '',
    taskList: []
  }

  private orderShow = false
  private taskId = ''

  get plantId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getTask()
  }

  getTask () {
    this.loading = true
    this.$axios.get(this.$apis.plan.selectFlowPlansByPlanId, {
      planId: this.plantId
    }).then(res => {
      this.infoData = res
    }).finally(() => {
      this.loading = false
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.plan.deleteFlowPlansTask, {
        taskId: id
      }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.getTask()
      })
    })
  }

  onShowOrderList (taskId: string) {
    this.taskId = taskId
    this.orderShow = true
  }
}
